<template>
  <!-- 首页管理页 -->
  <div class="user-manage">
    <div class="user-container">
      <div class="my-menus-user">
        <div class="user"  v-if="userInfo.identityList.length>1">
          <div class="name">
            <div style="text-align: center; width: 100%">
              <p class="userRole">
                <el-tag effect="dark" type="warning" :title="roleInfo.identityName">{{
                  roleInfo.identityName
                    ? roleInfo.identityName
                    : roleInfo.contactName
                }}</el-tag>
              </p>
            </div>
          </div>
          <div class="status" @click="changeRole" v-if="userInfo.identityList.length>1">
            <span style="color: #00a0e9">角色切换</span>
          </div>
        </div>
        <div class="menu">
          <sidebar/>
        </div>
      </div>
      <div class="content">
        <router-view :key="key" class="con"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getStore } from "../../js/utils/store";
import sidebar from './sidebar/';
export default {
  name: "user",
  components: {
      sidebar
    },
  data() {
    return {
      title: window.pageConfiguration.title,
      menus: [],
      headers: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      action: `${window.globalUrl.HOME_API}/admin/sys-file/userAvatar/update`,
      avatarData: { id: "" },
      activeIndex: "",
      defalutOpeneds: [
        "/user/fbcqxx",
        "/user/wdrz",
        "/user/znxx",
        "/user/smrz",
        "/user/auditPlan",
      ],
      key: Math.random(1),
      today: new Date(),
      imgSrc: "",
      roleInfo: {},
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    // 实时检测刷新token
    this.getMenus();
  },
  watch: {
    $route: {
      handler: function () {
        this.activeIndex = this.$route.path;
      },
      immediate: true,
    },
    identityObj(val){
      this.roleInfo=val;
    }
  },
  mounted() {
    this.roleInfo = this.identityObj ? this.identityObj: {};
    // this.initAvatar();
  },
  methods: {
    changeRole(item){
      this.$router.push({ name: "chooseRole" });
    },
    xgjs() {},
    // initAvatar() {
    //   // 获取用户头像
    //   getAvatar(this.userInfo.userName).then((res) => {
    //     let { code, data } = res;
    //     if (code == 0) {
    //       this.imgSrc = data;
    //     }
    //   });
    // },
    getMenus() {
      this.menus = [
        {
          name: "会员首页",
          index: "/memberCenter",
          img: "imgs/icon-member.png",
          img2: "imgs/icon-member2.png",
        },
        {
          name: "托管管理",
          index: "/user/wytg",
          img: "imgs/icon-system.png",
          children: [
            { name: "我要托管", index: "/user/wytg" },
            { name: "我要接管", index: "/user/wyjg" },
            { name: "已接管土地", index: "/user/yjgtd" },
            { name: "收藏的托管土地", index: "/user/sctgtd" },
            { name: "接管推送", index: "/user/jgts" },
            { name: "托管推送", index: "/user/tgts" },
          ],
        },
        {
          name: "农技管理",
          index: "/user/yczdjl",
          img: "imgs/icon-system.png",
          children: [{ name: "远程诊断记录", index: "/user/yczdjl" }],
        },
        {
          name: "资源资产管理",
          index: "/user/fbcqxx",
          img: "imgs/icon-system.png",
          children: [
            { name: "发布产权信息", index: "/user/fbcqxx" },
            { name: "资源资产流转推送", index: "/user/zyzclzts" },
            { name: "已报名项目", index: "/user/ybmxm" },
            { name: "竞价中项目", index: "/user/jjzxm" },
            { name: "竞价结果查看", index: "/user/jjjgck" },
            { name: "保证金审核", index: "/user/bzjsh"},
            { name: "公告时间管理", index: "/user/ggsjgl"},
            { name: "银行账户管理", index: "/user/yhzhgl"},
          ],
        },
        {
          name: "融资申请",
          index: "/user/wdrz",
          img: "imgs/icon-apply.png",
          children: [
            { name: "我的融资", index: "/user/wdrz" },
            { name: "抵押担保", index: "/user/dydb" },
            { name: "质押担保", index: "/user/zydb" },
            { name: "保证担保", index: "/user/bzdb" },
          ],
        },
        {
          name: "信息管理",
          index: "/user/fbtztg",
          img: "imgs/icon-msg.png",
          children: [
            // { name: "站内信息（之前的）", index: "/user/myMessage" },
            // { name: "我的订阅（之前的）", index: "/user/mySubscription" },
            { name: "发布通知公告", index: "/user/fbtztg" },
            { name: "上传法律法规相关文件", index: "/user/scflwj" },
            { name: "站内信息", index: "/user/znxx" },
          ],
        },
        // {
        //   name: "账户管理",
        //   index: "/user/smrz",
        //   img: "imgs/icon-user.png",
        //   children: [
        //     {
        //       name: "实名认证",
        //       index:
        //         this.userInfo.authSta == 5
        //           ? "/user/certificateByCard"
        //           : this.userInfo.authSta == 3
        //           ? "/user/certificateByLisence"
        //           : "/user/smrz",
        //       // : "/user/certification",
        //     },
        //     { name: "修改资料", index: "/user/xgzl" },
        //     { name: "修改密码", index: "/user/xgmm" },
        //     // {name: '实名认证（之前的）', index: '/user/certification'},
        //     // { name: "修改资料（之前的）", index: "/user/modifyInfo" },
        //     // { name: "修改密码（之前的）", index: "/user/resetPassword" },
        //     // { name: "缴款记录（之前的）", index: "/user/paymentRecords" },
        //   ],
        // },
        {
          name: "数据统计",
          index: "/user/tgxxtj",
          img: "imgs/icon-msg.png",
          children: [
            { name: "托管信息统计", index: "/user/tgxxtj" },
            { name: "资源资产流转统计", index: "/user/zyzctj" },
          ],
        },
        // {
        //   name: "专业化服务（之前的）",
        //   index: "/user/auditPlan",
        //   img: "imgs/icon-msg.png",
        //   children: [{ name: "规划条件审核", index: "/user/auditPlan" }],
        // },
      ];
      this.activeIndex = this.$route.path;
    },
    handleSelect(indexpath) {
      // 选中菜单项
      this.activeIndex = indexpath;
    },
    goMemberHome() {
      // 会员首页
      this.$router.push({ name: "memberCenter" });
    },
    handleAvatarBeforeUpload(file) {
      // 上传文件前
      this.avatarData = {
        id: this.userInfo.userId,
      };
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 6;
      if (!isJPG) {
        this.$message.error("上传图片为 JPG 或 PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 6MB!");
      }
      return isJPG && isLt2M;
    },
    handleAvatarSuccess(res, file, filelist) {
      if (res.code == 0) {
        this.$message.success("更换头像成功！");
      } else {
        this.$message.error(res.msg);
      }
      // this.$forceUpdate();
      this.$router.go(0);
    },
    logout() {
      // 退出登录
      localStorage.clear();
      // this.$router.push({name: 'homepage'})
      this.$confirm("是否退出系统, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("LogOut").then(() => {
          this.$router.push({ name: "homepage" });
        });
      });
    },
  },
};
</script>


<style lang="less" scoped>
.user-manage {
  background: #f2f2f2;
  padding-top: 78px;
  padding-bottom: 84px;
  .user-container {
    width: 1472px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 15px;
    // background-color: #00a0e9;
    .user-home {
      font-family: MicrosoftYaHei;
      font-size: 20px;
      color: #ffffff;
      line-height: 34px;
      text-align: left;
      // width: 211px;
      height: 34px;
      padding-left: 49px;
      background-color: #00a0e9;
      border-radius: 3px;
      margin: 0 auto;
      cursor: pointer;
    }
    .my-menus-user {
      width: 240px;
      text-align: left;
      .user {
        margin-bottom: 13px;
        background: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .name {
          display: flex;
          align-items: center;
          padding-left: 14px;
          padding-top: 15px;
          padding-right: 15px;
          box-sizing: border-box;
          .photo {
            width: 77px;
            height: 77px;
            background-color: #e3e3e3;
            border-radius: 38px;
            img {
              border-radius: 52px;
              width: 100%;
              height: 100%;
            }
          }
        }
        .status {
          height: 44px;
          padding-top: 10px;
          box-sizing: border-box;
          border-top: 1px solid #eeeeee;
          text-align: center;
          cursor: pointer;
          /deep/.el-dropdown{
            width: 100%;
          }
        }
      }
      .menu {
        background-color: #fff;
        padding-top: 22px;
        padding-left: 14px;
        padding-right: 15px;
        min-height: 950px;
      }
    }
    .content {
      width: calc(100% - 252px);
      background: #fff;
      min-height: 1060px;
      margin-left: 12px;
      // border-radius: 0px 15px 15px 0px;
      // border: solid 1px #c9c9c9;
      position: relative;

      .login-date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
        padding: 0 10px;
        line-height: 33px;
        .logout {
          // color: #333333;
          color: #00a0e9;
          font-family: MicrosoftYaHei-Bold;
          font-size: 16px;
          font-weight: 400;
          margin-left: 16px;
          cursor: pointer;
        }
      }
      .con {
        min-height: 911px;
      }
    }
  }
}
</style>

<style lang="less" scoped>
.user-container {
  .el-menu {
    border-right: none;
  }
  /deep/.el-menu-item{
    height: 50px !important;
    line-height: 50px !important;
  }
  .el-menu-item,
  .el-submenu__title {
    height: 34px;
    line-height: 34px;
    border-radius: 3px;
    color: #666666;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .el-submenu__title {
    padding-left: 26px !important;
    margin-top: 12px;
  }
  .el-menu-item:first-child {
    padding-left: 26px !important;
  }
  .el-submenu .el-menu-item {
    padding-left: 70px !important;
  }
  .el-menu {
    background-color: #fff;
  }
  /deep/.el-menu-item:focus,
  /deep/.el-menu-item:hover {
    outline: 0;
    color: #0668b3;
    background-color: rgba(6, 104, 179,0.2) !important;
  }
  /deep/.el-submenu__title:hover {
    color: #0668b3;
    background-color: rgba(6, 104, 179,0.2) !important;
  }
  /deep/.el-menu-item.is-active {
    color: #fff;
    background-color: #0668b3 !important;
  }
  .el-submenu__title i {
    color: #666666;
    font-size: 16px;
  }
  .el-submenu__icon-arrow {
    top: 45%;
  }

  .photo {
    .el-upload {
      width: 100%;
      height: 100%;
    }
  }
}
/deep/.el-tag {
  height: 26px !important;
  line-height: 26px !important;
  margin-right: 4px;
  margin-bottom: 6px;
}
.xgjs {
  cursor: pointer;
}
.userRole {
  font-size: 16px;
  margin: 0px;
  padding: 4px 0px 8px 4px;
  // display: flex;
  // justify-content: space-between;
  >span{
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
/deep/.el-dropdown {
  color: #409eff;
}
</style>
